h1,
.is-h1,
h2,
.is-h2,
h3,
.is-h3,
h4,
.is-h4,
h5,
.is-h5,
h6,
.is-h6 {
  font-family: font-family(title);
  color: color(primary);
  font-weight: bold;
  line-height: 1.2;
}

@for $i from 4 through 6 {
    h#{$i},
    .is-h#{$i},
    .has-font-size-#{$i} {
        font-size: font-size($i);
    }
}

h1,
.is-h1 {
    margin-top: spacer(3);
    margin-bottom: spacer(2);
    /*font-size: calc(font-size(1) - 0.813);*/
    font-size: 2rem;

    @include min(bp(sm)) {
        font-size: font-size(1);
        margin-top: spacer(6);
        margin-bottom: spacer(4);
    }
}

h2,
.is-h2 {
    margin-top: spacer(3);
    margin-bottom: spacer(2);
    /*font-size: calc(font-size(2) - 0.25);*/
    /*font-size: calc(font-size(2) - 4px);*/
    /*TODO fix fluid typography!!!!*/
    font-size: 1.75rem;

    @include min(bp(sm)) {
        font-size: font-size(2);
        margin-top: spacer(5);
        margin-bottom: spacer(3);
    }
}

h3,
.is-h3 {
    margin-top: spacer(4);
    margin-bottom: spacer(2);
    /*font-size: calc(font-size(3) - 0.312rem);*/
    font-size: 1.5625rem;
    @include min(bp(sm)) {
        font-size: font-size(3);
    }
}

h4,
.is-h4 {
  margin-top: spacer(3);
  margin-bottom: spacer(2);
}

h5,
.is-h5 {
  margin-top: spacer(3);
  margin-bottom: spacer(1);
}

h6,
.is-h6 {
  margin-top: spacer(2);
  margin-bottom: spacer(1);
}

// ------------------------------
// KSS Documentation
// ------------------------------

// Titles
//
// Markup:
// <h1>Lorem ipsum heading 1</h1>
// <h2>Lorem ipsum heading 2</h2>
// <h3>Lorem ipsum heading 3</h3>
// <h4>Lorem ipsum heading 4</h4>
// <h5>Lorem ipsum heading 5</h5>
// <h6>Lorem ipsum heading 6</h6>
// <p class="is-h1">Lorem ipsum heading 1</p>
// <p class="is-h2">Lorem ipsum heading 2</p>
// <p class="is-h3">Lorem ipsum heading 3</p>
// <p class="is-h4">Lorem ipsum heading 4</p>
// <p class="is-h5">Lorem ipsum heading 5</p>
// <p class="is-h6">Lorem ipsum heading 6</p>
//
// Styleguide Atoms.Typography.titles