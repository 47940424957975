// ------------------------------
// Import fonts
// ------------------------------
$font-path: "/content/fonts/";

@font-face {
    font-family: 'Museo';
    src: url('#{$font-path}museo/museo100-regular-webfont-Regular.woff2') format('woff2'), url('#{$font-path}museo/museo100-regular-webfont-Regular.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo';
    src: url('#{$font-path}museo/museo300-regular-webfont-Regular.woff2') format('woff2'), url('#{$font-path}museo/museo300-regular-webfont-Regular.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo';
    src: url('#{$font-path}museo/museo500-regular-webfont-Regular.woff2') format('woff2'), url('#{$font-path}museo/museo500-regular-webfont-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo';
    src: url('#{$font-path}museo/museo700-regular-webfont-Regular.woff2') format('woff2'), url('#{$font-path}museo/museo700-regular-webfont-Regular.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo';
    src: url('#{$font-path}museo/museo900-regular-webfont-Regular.woff2') format('woff2'), url('#{$font-path}museo/museo900-regular-webfont-Regular.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Museo-sans';
    src: url('#{$font-path}museo/museosans-100-webfont.woff2') format('woff2'), url('#{$font-path}museo/museosans-100-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo-sans';
    src: url('#{$font-path}museo/museosans-300-webfont.woff2') format('woff2'), url('#{$font-path}museo/museosans-300-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo-sans';
    src: url('#{$font-path}museo/museosans_500-webfont.woff2') format('woff2'), url('#{$font-path}museo/museosans_500-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo-sans';
    src: url('#{$font-path}museo/museosans_700-webfont.woff2') format('woff2'), url('#{$font-path}museo/museosans_700-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo-sans';
    src: url('#{$font-path}museo/museosans_900-webfont.woff2') format('woff2'), url('#{$font-path}museo/museosans_800-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

// ------------------------------
// Sass Variables
// ------------------------------
$font-family-prefix: --font-family-;
$font-families: (
  paragraph: ("Museo-sans", "Helvetica Neue", Arial, sans-serif),
  title: ("Museo-sans", "Helvetica Neue", Arial, sans-serif),
  highlight: ("Museo", "Helvetica Neue", Arial, sans-serif),
  icon: ('icomoon')
);

// ------------------------------
// Set function
// ------------------------------
@function font-family($font-family, $true-val:false) {
  @if $use-css-var == true {
    @if $true-val == true {
      @return map-get($font-families, $font-family); //True Val
    } @else {
      @return var(#{$font-family-prefix}#{$font-family}); //CSS Var
    }
  } @else {
    @return map-get($font-families, $font-family); //Disabled CSS Var
  }
}

// ------------------------------
// Set root variables
// ------------------------------
@if $use-css-var == true {
  #{$root-default} {
    @each $name, $font-family in $font-families {
      #{$font-family-prefix}#{$name}: $font-family; //Not using em as not being use in media queries
    }
  }
}

// ------------------------------
// KSS Documentation
// ------------------------------

// Font Families
//
// Font Families are in the map `$font-families`
//
// ---------------
// * `paragraph`: Helvetica Neue,
// * `title`: CoRethink,
// * `highlight`: CoRethink
// * `icon`: icomoon
// ---------------
//
// Font Families can be called in the sass project using the functions:
//
// `font-family($font-family, $true-val:false)` which will output by default the CSS variable
//
// Usage of font-family():
// * `font-family(title)` => `var(--font-family-title)` If $use-css-var = true
// * `font-family(title)` => `"CoRethink", "Helvetica Neue", Arial, sans-serif` If $use-css-var = false
// * `font-family(title, true)` => `"CoRethink", "Helvetica Neue", Arial, sans-serif`
//
// Styleguide Variables.fontfamilies