﻿.opportunities {
    @include clearfix();

    .item {
        display: block;
        text-decoration: none;
        background-color: color-gray(10);

        .row {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
        }

        .wrapper {
            padding: 1rem;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
        }

        .icon {

            .wrapper {
                background-color: color(ter);
                text-align: center;
                justify-content: center;
                align-items: center;
            }

            img {
                max-width: 100%;
            }
        }

        .details {
            color: color(text, dark);

            .wrapper {
                flex-direction: column;
                padding-left: 0;
                justify-content: center;
            }

            h6 {
                color: color(text, dark);
                font-size: 1.4rem;
            }

            .commitment{
                color: color(ter);
            }
        }
    }
}
