/*blockquote {
  & > *:last-child {
    margin-bottom: 0;
  }
}*/

// ------------------------------
// KSS Documentation
// ------------------------------

// Blockquotes
//
// markup:
// <blockquote>
//     <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Atque saepe laboriosam itaque possimus. Ducimus recusandae iste, ullam officiis odio, id rerum labore eligendi optio voluptatem perferendis corporis dignissimos ipsum odit!</p>
// </blockquote>
//
// Styleguide Atoms.Typography.blockquotes
