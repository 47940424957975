﻿.has-h-100 {
    height: 100%;
}

.has-w-100 {
    width: 100%;
}


// Sizing
//
// - `.has-h-100`: height 100%;
// - `.has-w-100`: width 100%;
//
// Styleguide Atoms.Modifiers.sizing