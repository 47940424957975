﻿//Justify
.is-justify-start {
    justify-content: flex-start !important;
}

.is-justify-end {
    justify-content: flex-end !important;
}

.is-justify-around {
    justify-content: space-around !important;
}

.is-justify-between {
    justify-content: space-between !important;
}

.is-justify-center {
    justify-content: center !important;
}

//Align

.is-align-start {
    align-items: flex-start !important;
}

.is-align-end {
    align-items: flex-end !important;
}

.is-align-around {
    align-items: space-around !important;
}

.is-align-between {
    align-items: space-between !important;
}

.is-align-center {
    align-items: center !important;
}

.is-flex-wrap {
    flex-wrap: wrap !important;
}

.is-flex-column {
    flex-direction: column !important;
}

@each $bp, $bpKey in $breakpoints {
    @include min(#{bp(#{$bp})}) {
        .is-justify-#{$bp}-start {
            justify-content: flex-start !important;
        }

        .is-justify-#{$bp}-end {
            justify-content: flex-end !important;
        }

        .is-justify-#{$bp}-around {
            justify-content: space-around !important;
        }

        .is-justify-#{$bp}-between {
            justify-content: space-between !important;
        }

        .is-justify-#{$bp}-center {
            justify-content: center !important;
        }

        .is-align-#{$bp}-start {
            align-items: flex-start !important;
        }

        .is-align-#{$bp}-end {
            align-items: flex-end !important;
        }

        .is-align-#{$bp}-around {
            align-items: space-around !important;
        }

        .is-align-#{$bp}-between {
            align-items: space-between !important;
        }

        .is-align-#{$bp}-center {
            align-items: center !important;
        }
    }
}


// ------------------------------
// KSS Documentation
// ------------------------------

// Flex utilities
//
// Styleguide Atoms.Modifiers.flex

// Justify content
//
// Flex utilities helps to justify flex content.
//
// `.is-justify-{breakpoint}-{alignment value}`, breakpoint is optional.
//
// **alignment value:**
//
// * end
// * start
// * center
// * between
// * around
//
// markup:
// <div class="is-d-flex {{modifier_class}}">
//   <div class="kss-box" style="flex: 0 0 60px">Parent .is-d-flex.{{modifier_class}}</div>
//   <div class="kss-box" style="flex: 0 0 60px">Parent .is-d-flex.{{modifier_class}}</div>
// </div>
//
// .is-justify-end - is-justify-end
// .is-justify-start - is-justify-start
// .is-justify-center - is-justify-center
// .is-justify-between - is-justify-between
// .is-justify-around - is-justify-around
//
// Styleguide Atoms.Modifiers.flex.justify

// Align content
//
// Flex utilities helps to align flex content.
//
// `.is-align-{breakpoint}-{alignment value}`, breakpoint is optional.
//
// **alignment value:**
//
// * end
// * start
// * center
// * between
// * around
//
// markup:
// <div class="is-d-flex {{modifier_class}}" style="height: 350px; border: 1px solid grey">
//   <div class="kss-box" style="flex: 0 0 60px; height: auto">Parent .is-d-flex.{{modifier_class}}</div>
//   <div class="kss-box" style="flex: 0 0 60px; height: auto">Parent .is-d-flex.{{modifier_class}}</div>
// </div>
//
// .is-align-end - is-align-end
// .is-align-start - is-align-start
// .is-align-center - is-align-center
// .is-align-between - is-align-between
// .is-align-around - is-align-around
// .is-flex-column -  is-flex-column - currently no breakpoints
//
// Styleguide Atoms.Modifiers.flex.align

// Wrap
//
// `.is-flex-wrap`.
//
// Allow to wrap flex content
//
// Styleguide Atoms.Modifiers.flex.wrap