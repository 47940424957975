/*Atomic Bulldog version 1.0.0*/

//Global settings
@import "settings/atomic-bulldog-settings";

//vendors
@import "vendors/family";
@import "vendors/bootstrap.scss";
@import "vendors/flatpickr.scss";

//functions
@import "functions/em";
@import "functions/map-deep-get";

//mixins
@import "mixins/media-queries";
@import "mixins/focus-style";
@import "mixins/_clearfix";

//variables
@import "variables/aspect-ratios";
@import "variables/box-shadow";
@import "variables/root-classes";
@import "variables/breakpoints";
@import "variables/colors";
@import "variables/container-sizes";
@import "variables/font-families";
@import "variables/font-sizes";
@import "variables/grid";
@import "variables/spacers";
@import "variables/z-index";

//atoms
@import "atoms/animations";
@import "atoms/aspect-ratio-container";
@import "atoms/burger";
@import "atoms/focus";
@import "atoms/img-fluid";
@import "atoms/overlay";
@import "atoms/application-heading";
@import "atoms/accordion.scss";


//Forms
@import "atoms/forms/form-text";
@import "atoms/forms/inputs";
@import "atoms/forms/label";
@import "atoms/forms/validation";

//typography
@import "atoms/typography/blockquotes";
@import "atoms/typography/lists";
@import "atoms/typography/paragraph";
@import "atoms/typography/titles";
@import "atoms/typography/typography-global";

//modifiers
@import "atoms/modifiers/fixed-nav-padding";
@import "atoms/modifiers/bg-color";
@import "atoms/modifiers/box-shadow";
@import "atoms/modifiers/display";
@import "atoms/modifiers/has-border-cols";
@import "atoms/modifiers/flex";
@import "atoms/modifiers/overflow-hidden";
@import "atoms/modifiers/rounded";
@import "atoms/modifiers/sizing";
@import "atoms/modifiers/spacing";
@import "atoms/modifiers/text-color";
@import "atoms/modifiers/text-font-family";
@import "atoms/modifiers/text-position";
@import "atoms/modifiers/text-transform";

//molecules
@import "molecules/form-group";
@import "molecules/login";
@import "molecules/navigation";
@import "molecules/_button.scss";
@import "molecules/_opportunities-list.scss";
@import "molecules/_jumbo.scss";
@import "molecules/_sidebar.scss";
@import "molecules/_opportunity-content.scss";
@import "molecules/_backgrounds.scss";
@import "molecules/_account-footer.scss";
@import "molecules/_message-popup.scss";
@import "molecules/_screen-frame.scss";
@import "molecules/_counter.scss";



//organisms

//templates
@import "templates/main-template";


/*temp test*/

.btn {
    -webkit-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transition-property: color,background-color;
    transition-property: color,background-color;
}

.btn.btn-orange:hover, .btn.btn-orange:focus, .btn.btn-orange:active, .btn.btn-orange.active {
    background: transparent;
}

.opportunity-content p, .opportunity-content ul {
    font-family: "Museo-sans", "Helvetica Neue", Arial, sans-serif !important;
}

fieldset .opportunity-content h5.panel-title {
    text-align: left;
}