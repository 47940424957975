﻿.application-hero {
    background-color: color-gray(10);
    border-bottom: 1px solid color-gray(20);

    h1 {
        padding: 0;
        margin: 0;
        text-align: center;
        color: color(text, dark);
    }

    @include max(bp(md)) {
        padding: 3rem 0;
    }

    @include min(bp(md)) {
        padding: 5rem 0;
    }
}
