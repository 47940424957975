﻿
// **.is-animated** triggers animation via IntersectionObserver in scroll-in-animation.js
.quote {
    .row:nth-of-type(odd) {
        .quote--img {
            .scroll-fade-in-effect {
                transform: translate3d(-100px, 0, 0);
                opacity: 0;

                @include min(bp(sm)) {
                    transform: translate3d(-200px, 0, 0);
                }

                &.animate-slide-in.is-animated {
                    animation: slide-in-left 1.5s ease forwards, opacity-fade-in 2.5s ease forwards;
                }
            }

            @media (prefers-reduced-motion: reduce) {
                .scroll-fade-in-effect {
                    transform: none;
                    opacity: 1;
                }
            }
        }

        .fade-in-quote {
            .scroll-fade-in-effect {
                transform: translate3d(100px, 0, 0);
                opacity: 0;

                @include min(bp(sm)) {
                    transform: translate3d(200px, 0, 0);
                }

                &.animate-slide-in.is-animated {
                    animation: slide-in-right 1.5s ease forwards, opacity-fade-in 2.5s ease forwards;
                }
            }

            @media (prefers-reduced-motion: reduce) {
                .scroll-fade-in-effect {
                    transform: none;
                    opacity: 1;
                }
            }
        }
    }

    .row:nth-of-type(even) {
        .quote--img {
            .scroll-fade-in-effect {
                transform: translate3d(100px, 0, 0);
                opacity: 0;

                @include min(bp(sm)) {
                    transform: translate3d(200px, 0, 0);
                }

                &.animate-slide-in.is-animated {
                    animation: slide-in-right 1.5s ease forwards, opacity-fade-in 2.5s ease forwards;
                }
            }

            @media (prefers-reduced-motion: reduce) {
                .scroll-fade-in-effect {
                    transform: none;
                    opacity: 1;
                }
            }
        }

        .fade-in-quote {
            .scroll-fade-in-effect {
                transform: translate3d(-100px, 0, 0);
                opacity: 0;

                @include min(bp(sm)) {
                    transform: translate3d(-200px, 0, 0);
                }

                &.animate-slide-in.is-animated {
                    animation: slide-in-left 1.5s ease forwards, opacity-fade-in 2.5s ease forwards;
                }
            }

            @media (prefers-reduced-motion: reduce) {
                .scroll-fade-in-effect {
                    transform: none;
                    opacity: 1;
                }
            }
        }
    }
}

.service-listing--card, .get-involved--card, .onward-journey--card, .membership--card {
    &.scroll-fade-in-effect.animate-slide-in {
        transform: translate3d(0, 200px, 0);
        opacity: 0;

        &.is-animated {
            animation: slide-in-up 0.5s ease forwards, opacity-fade-in 2.5s ease forwards;
        }

        @media (prefers-reduced-motion: reduce) {
            transform: none;
            opacity: 1;
        }
    }
}

@keyframes slide-in-up {
    0% {
        transform: translate3d(0, 200px, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}



@keyframes slide-in-left {
    0% {
        transform: translate3d(-200px, 0, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes opacity-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slide-in-right {
    0% {
        transform: translate3d(200px, 0, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}
