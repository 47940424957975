$textual-form-inputs-class: ".fake-input, input[type="email"].form-control, input[type="number"].form-control, input[type="password"].form-control, input[type="tel"].form-control, input[type="url"].form-control, input[type="text"].form-control, input[type="search"].form-control, textarea, select.form-control, .input, input.form-control, .form-control";

$input-size: 22px;

input {
  cursor: pointer;
}

input[type="radio"],
input[type="checkbox"] {
    width: $input-size;
    height: $input-size;
}

textarea{
    resize: vertical;
    min-height: 100px;
    max-height: 225px;
}

#{$textual-form-inputs-class} {
    display: block;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: color-gray(20);
    background-color: white;
    padding: 0.25rem 0.5rem;
    font-weight: 500;
    outline: none;
    color: color(text);
    min-width: 30px;
    box-shadow: none;
    border-radius: 0px;
    font-size: 1rem;
}

.form-h4 {
    margin-top: 0;
    color: color-gray(100);
    text-align: center;

    &.text-left {
        text-align: left;
    }

    &.text-right {
        text-align: right;
    }
}

.form-h1, .form-h2, .form-h3, .form-h4, .form-h5 {
    margin-top: 0;
    color: color-gray(100);
    text-align: center;

    &.text-left {
        text-align: left;
    }

    &.text-right {
        text-align: right;
    }
}

fieldset, .fieldset {
    padding: 2.5rem 1.25rem;
    border: 1px solid color-gray(20);
    background-color: color-gray(10);
    height: 100%; //fits within its flexed container
    @include min(bp(sm)) {
        padding: 2.5rem;
    }

    h1,h2,h3,h4,h5 {
        margin-top: 0;
        color: color-gray(100);
        text-align: center;

        &.text-left {
            text-align: left;
        }

        &.text-right {
            text-align: right;
        }
    }
}

.form-info-text{
    color: color(text);
    text-decoration: underline;
    display: block;
    margin-top: 0.5rem;
    font-weight: 500;
    font-size: 0.9rem;
    text-align: right;
    &:hover,
    &:focus{
        text-decoration: none;
    }
}

.form-check {
    label {
        @include clearfix();
        position: relative;

        input {
            float: left;
            width: 20px;
            display: none;
            height: 0;
            width: 0;

            &:checked {
                ~ span {
                    &::after {
                        display: block;
                    }
                }
            }

            &[disabled] {
                ~ span {
                    color: color-gray(30);
                    cursor: default;

                    &::before {
                        border-color: color-gray(30);
                    }

                    &::after {
                        background-color: color-gray(20);
                    }
                }
            }
        }

        span {
            font-weight: 400;
            float: left;
            width: calc(100% - 20px);
            padding-left: 1.75rem;

            &::before, &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
            }

            &::before {
                display: block;
                width: 1.25rem;
                height: 1.25rem;
                border: 1px solid color-gray(40);
                background-color: white;
            }

            &::after {
                display: none;
                width: 0.75rem;
                height: 0.75rem;
                background-color: color(primary);
                top: 4px;
                left: 4px;
            }
        }

        &.form-check-label {
            span {
                &::before {
                    border-radius: 50%;
                }

                &::after {
                    border-radius: 50%;
                }
            }
        }
    }

    &.inverted {
        //for checkbox only at present
        input{
            ~ span{
                &::after{
                    display: block;
                }
            }
            &:checked{
                ~ span{
                    &::after{
                        display: none;
                    }
                }
            }
        }
    }
}

.listing-item {
    margin-top: 14px;
    margin-bottom: 14px;

    .inner {
        text-align: center;
        cursor: pointer;
        padding: 1rem;
        background: #ededed;
    }

    &.active {
        .inner {
            background-color: darken(#ededed, 10%);
        }
    }
}

// ------------------------------
// KSS Documentation
// ------------------------------

// Form elements
//
// Styleguide Atoms.Forms

// ------------------------------
/////////////////////////////////
// ------------------------------

// Inputs
//
// Markup:
// <input type="email" name="test1" placeholder="email">
// <input type="number" name="test2" placeholder="number">
// <input type="password" name="test3" placeholder="password">
// <input type="tel" name="test4" placeholder="tel">
// <input type="text" name="test5" placeholder="text">
// <textarea></textarea>
// <input type="radio" name="test6" value="radio">
// <input type="checkbox" name="test7" value="checkbox">
// <label class="custom-input"><input type="radio" name="test8" value="radio"><span class="custom-input-indicator"></span></label>
// <label class="custom-input"><input type="checkbox" name="test9" value="checkbox"><span class="custom-input-indicator"></span></label>
// <select>
//   <option value="option1">option1</option>
//   <option value="option2">option2</option>
//   <option value="option3">option3</option>
//   <option value="option4">option4</option>
// </select>
//
// Styleguide Atoms.Forms.inputs