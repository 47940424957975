header {
    padding: 1rem 0;
    border-bottom: 1px solid color-gray(20);

    @include min(bp(md)) {
        padding: 1.5rem 0;
    }

    .row {
        display: flex;
        align-items: center;
    }

    .icon-banner {
        width: 120px;

        @include min(bp(md)) {
            width: 175px;
        }
    }
}

nav.navbar-override {
    padding: 1rem 0;
    background-color: white;
    border: none;
    border-bottom: 1px solid color-gray(20);
    margin: 0;

    @include min(bp(md)) {
        padding: 1.5rem 0;
    }

    .icon-banner {
        width: 120px;

        @include min(bp(md)) {
            width: 175px;
        }
    }

    .navbar-collapse {
        border-top: none;

        ul {
            margin-bottom: 0;

            li {
                a {
                    padding-left: 0;
                    padding-right: 0;
                    text-transform: uppercase;
                    color: color(text, dark);
                    font-weight: 600;
                    font-size: 0.9rem;
                }
            }
        }

        @include min(bp(sm)) {
            float: right;

            ul {
                li {
                    display: inline-block;
                    list-style-type: none;

                    a {
                        position: relative;
                        padding: 1.5rem 0;

                        &::after {
                            content: "";
                            display: block;
                            width: 70%;
                            left: 15%;
                            position: absolute;
                            bottom: 0;
                            height: 4px;
                            background-color: color(primary);
                            display: none;
                        }

                        &:hover,
                        &:focus {
                            text-decoration: none;
                            color: color(text, dark);

                            &::after {
                                display: block;
                            }
                        }
                    }

                    + li {
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
}