﻿.sidebar {

    h2, h3, h4, h5, h6 {
        color: black;
    }

    .sidebar-title {
        margin-top: 0;
        font-size: 1.4rem;
    }

    .sidebar-sub-title{
        font-size: 1rem;
    }
}
