label {
    font-size: font-size(5);
    font-family: font-family(title);
    font-weight: bolder;
    margin-bottom: 0;
    cursor: pointer;
    display: block;

    &.input-label {
        font-size: font-size(paragraph);
        font-weight: lighter;
        display: inline-flex;
        align-items: flex-start; //Because of the font use otherwise center
        input[type="radio"],
        input[type="checkbox"] {
            margin-right: spacer(1);
        }
    }
}

// ------------------------------
// KSS Documentation
// ------------------------------

// Label
//
// Markup:
// <label>Test Label</label>
// <label class="input-label">Test Label</label>
//
// Styleguide Atoms.Forms.label