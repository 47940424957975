﻿.message-popup {
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    z-index: 99;
    width: calc(100% - 4rem);
    max-width: 350px;
}

.message-popup strong {
    margin-bottom: 0.25rem;
    display: block;
}

.message-popup button.close {
    position: absolute;
    top: 1rem;
    right: 1rem;
}
