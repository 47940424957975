.img-fluid {
  height: auto;
  max-width: 100%;

  &.is-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

img.lazy {
    /*background-color: color-gray(10);
    color: color-gray(10);*/
    font-size: 0;
}

// ------------------------------
// KSS Documentation
// ------------------------------

// Images
//
// Images will need to be resize in the view. We use Umbraco `GetCropUrl` to do so. 
// 
//
// ```cshtml
// @(item.Image?.GetCropUrl(ImageSize.{{Size}}, null, quality: ImageQuality.{{quality}})
// ```
//
// We have a set of `imagesSize` set in the project, you will need to reference these one at the beginning of your view using `@using Rethink.Models.Constants;`
//
// - `ImageSize.{{Size}}`
//   - Xs = 400
//   - Sm = 900
//   - Md = 1200
//   - Lg = 1800
//   - Xl = 1920
//   - Xxl = 2560
// - `ImageQuality.{{quality}}`
//   - Low = 40
//   - Default = 60
//   - High = 80
//
// **Example of using `GetCropUrl` with `srcset`:**
//
// ```cshtml
// @using Rethink.Models.Constants;
//
// <img
//   srcset="
//   @(item.Image?.GetCropUrl(ImageSize.Sm, null, quality: ImageQuality.Default)) @(ImageSize.Sm)w
//   @(item.Image?.GetCropUrl(ImageSize.Md, null, quality: ImageQuality.Default)) @(ImageSize.Md)w
//   @(item.Image?.GetCropUrl(ImageSize.Lg, null, quality: ImageQuality.Default)) @(ImageSize.Lg)w
//   "
//   src="@(item.Image?.GetCropUrl(ImageSize.Xs, null, quality: ImageQuality.Default)) @(ImageSize.Xs)w"
//   alt="alt text">
// ```
//
// Styleguide Atoms.images

// ------------------------------
// //////////////////////////////
// ------------------------------

// Images Fluid
//
// `.img-fluid` Fluid images class, to center the image if this one is too small use `.is-center`
//
// Markup:
// <div style="max-width: 600px"> <!-- Just for KSS -->
//    <img class="{{modifier_class}}"
//     srcset="https://source.unsplash.com/random/320x320 320w,
//         https://source.unsplash.com/random/570x570 570w,
//         https://source.unsplash.com/random/860x860 860w,
//         https://source.unsplash.com/random/1150x1150 1150w"
//     src="https://source.unsplash.com/random/860x860"
//     alt="Alternative text">
//     <!--sizes="(min-width: 600px) 33vw, 100vw"--> <!--Use sizes in <img> to serve the right image size depending on the way it is display-->
// </div>
//
// .img-fluid - image is fluid
// .img-fluid.is-center - image is fluid and centered
//
// Styleguide Atoms.images.fluid

// ------------------------------
// //////////////////////////////
// ------------------------------

// Responsive image, Srcset (`img` tag)
//
// If the browser does not support `srcset` it will fallback to `src` (IE11) More infos => https://salferrarello.com/responsive-images-srcset/
//
// <p class="ciu_embed" data-feature="srcset" data-periods="future_1,current,past_1,past_2" data-accessible-colours="false">
// <a href="http://caniuse.com/#feat=srcset"> Can I Use srcset?</a> Data on support for the srcset feature across the major browsers from caniuse.com.
// </p>
//
// Markup:
// <div class="container">
//     <img class="img-fluid"
//     srcset="http://via.placeholder.com/320x150 320w,
//     http://via.placeholder.com/600x281 600w,
//     http://via.placeholder.com/900x422 900w,
//     http://via.placeholder.com/1200x563 1200w,
//     http://via.placeholder.com/1800x844 1800w"
//     src="http://via.placeholder.com/320x150?text=Fallback"
//     alt="image description">
//     <div class="row">
//         <div class="col-4">
//             <img class="img-fluid"
//             srcset="http://via.placeholder.com/320x150 320w,
//             http://via.placeholder.com/600x281 600w,
//             http://via.placeholder.com/900x422 900w,
//             http://via.placeholder.com/1200x563 1200w,
//             http://via.placeholder.com/1800x844 1800w"
//             sizes="(min-width: 600px) 33vw, 100vw"
//             src="http://via.placeholder.com/320x150?text=Fallback"
//             alt="image description">
//         </div>
//         <div class="col-4">
//             <img class="img-fluid"
//             srcset="http://via.placeholder.com/320x150 320w,
//             http://via.placeholder.com/600x281 600w,
//             http://via.placeholder.com/900x422 900w,
//             http://via.placeholder.com/1200x563 1200w,
//             http://via.placeholder.com/1800x844 1800w"
//             sizes="(min-width: 600px) 33vw, 100vw"
//             src="http://via.placeholder.com/320x150?text=Fallback"
//             alt="image description">
//         </div>
//         <div class="col-4">
//             <img class="img-fluid"
//             srcset="http://via.placeholder.com/320x150 320w,
//             http://via.placeholder.com/600x281 600w,
//             http://via.placeholder.com/900x422 900w,
//             http://via.placeholder.com/1200x563 1200w,
//             http://via.placeholder.com/1800x844 1800w"
//             sizes="(min-width: 600px) 33vw, 100vw"
//             src="http://via.placeholder.com/320x150?text=Fallback"
//             alt="image description">
//         </div>
//     </div>
// </div>
//
// Styleguide Atoms.images.responsive

// ------------------------------
// //////////////////////////////
// ------------------------------

// Responsive background image
//
// This project use `.picturefill-background` (See vendors) to add a similar behavior as `srcset`.
//
// You will need at add the class `.picturefill-background` to your container and set your images using span as in the example.
//
// You can get more information about this feature on [github](https://github.com/M6Web/picturefill-background)
//
// Markup:
// <div class="container">
//     <div class="picturefill-background aspect-ratio-container is-16x9 bg-cover is-center">
//       <span data-src="http://via.placeholder.com/320x150"></span>
//       <span data-src="http://via.placeholder.com/600x300" data-media="(min-width: 320px)"></span>
//       <span data-src="http://via.placeholder.com/1200x600" data-media="(min-width: 600px)"></span>
//       <span data-src="http://via.placeholder.com/2560x600" data-media="(min-width: 1200px)"></span>
//     </div>
// </div>
//
// Styleguide Atoms.images.responsive.background

// ------------------------------
// //////////////////////////////
// ------------------------------

// Image Lazy load
//
// Use lazy load for images. You can use lazy load using the class `img.lazy`. You can use it using the `data-src` and `data-srcset` attributes.
//
// Markup:
// <img class="img-fluid lazy"
// data-srcset="https://source.unsplash.com/random/860x860 320w,
// https://source.unsplash.com/random/860x860 600w,
// https://source.unsplash.com/random/860x860 900w,
// https://source.unsplash.com/random/860x860 1200w,
// https://source.unsplash.com/random/860x860 1800w"
// data-src="https://source.unsplash.com/random/860x860"
// alt="image description">
//
// Styleguide Atoms.images.lazyLoad