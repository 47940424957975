// ------------------------------
// Sass Variables
// ------------------------------
$color-prefix: --color-;
$color-gray-prefix: --color-gray-;
$color-themes: (
    primary:
    (
      base: #eb2629, 
      dark: #bc181c, 
      light: #f37c7e,
    ),
    sec:
    (
      base: #17b4b9, 
      dark: #129094, 
      light: #8bd9dc,
    ),
    ter:
    (
      base: #fca941, 
      dark: #e2983a, 
      light: rgba(250,168,74,0.2), 
    ),
    quat: 
    (
      base: #378319,
      dark: #378319,
      light: #378319
    ),
    text:
    (
      base: #4f4f4f,
      dark: black, 
      light: #777575,
    ),
);

$gray-themes: (
  0: hsl(0, 0%, 100%),
  10: hsl(0, 0%, 96%), // From design Breadcrumb color and grey bg color
  20: hsl(0, 0%, 93%),
  30: hsl(0, 0%, 82%),
  40: hsl(0, 0%, 75%),
  50: hsl(0, 0%, 68%), // From design Text color
  60: hsl(0, 0%, 60%),
  70: hsl(0, 0%, 48%),
  80: hsl(0, 0%, 30%),
  90: hsl(0, 0%, 15%),
  100: hsl(0, 0%, 0%) // From design Footer bg
);

// ------------------------------
// Set color function
// ------------------------------
// retrieve color from map with Sass ie. `color(primary, base)`
/* purgecss start ignore */
@function color($color-name, $color-variant:null, $true-val:false) {
  // if we enable css var
  @if $use-css-var == true {
    // we need to return the color value
    @if $true-val == true {
      // color variant is optional
      @if ($color-variant != null) {
        // map inception, need two deep
        @return map-get(map-get($color-themes,$color-name),$color-variant);
      } @else {
        // Default color
        @return map-get(map-get($color-themes,$color-name), base);
      } // if we're only returning the CSS4 variable
    } @else {
      // color variant is optional
      @if ($color-variant != null) {
        // map inception, need two names
        @return var(#{$color-prefix}#{$color-name}-#{$color-variant});
      } @else {
        // Default color, one name
        @return var(#{$color-prefix}#{$color-name}-base);
      }
    }
  } @else {
    // color variant is optional
    @if ($color-variant != null) {
      // map inception, need two deep
      @return map-get(map-get($color-themes,$color-name),$color-variant);
    } @else {
      // Default color
      @return map-get(map-get($color-themes,$color-name), base);
    }
  }
}
/* purgecss end ignore */
/* purgecss start ignore */
@function color-gray($gray-theme, $true-val:false) {
  @if $use-css-var == true {
    @if $true-val == true {
      @return map-get($gray-themes, $gray-theme); // True Val
    } @else {
      @return var(#{$color-gray-prefix}#{$gray-theme});
      // CSS Var
    }
  } @else {
    @return map-get($gray-themes, $gray-theme); // Disabled CSS Var
  }
}
/* purgecss end ignore */
// ------------------------------
// Set root variables
// ------------------------------
@if $use-css-var == true {
  #{$root-default} {
    @each $name, $color in $color-themes {
      @if type-of($color) == "map" {
        @each $subname, $subcolor in $color {
          #{$color-prefix}#{$name}-#{$subname}: $subcolor;
        }
      } @else if type-of($color) == "color" {
        #{$color-prefix}#{$name}: $color;
      }
    }
    @each $name, $gray-theme in $gray-themes {
      #{$color-gray-prefix}#{$name}: $gray-theme;
    }
  }
}
// ------------------------------
// KSS Documentation
// ------------------------------

// Colors
//
// Colors are in the map `$color-themes`. Each color themes have 5 variables:
// * Base
// * Dark
// * Light
// * Transparent
// * Contrast
//
// Colors can be called in the sass project using the functions:
//
// `color($color-name, $color-variant:null, $true-val:false)` which will output by default the CSS variable
//
// Usage of font-family():
// * `color(danger)` => `var(--color-danger-base)` If $use-css-var = true
// * `color(danger)` => `#c65556` If $use-css-var = false
// * `color(success, base, true)` => `#c65556`
// * `color(success, light)` => `var(--color-success-light)` If $use-css-var = true
// * `color(success, light)` => `#76bd7a` If $use-css-var = false
// * `color(success, light, true)` => `#76bd7a`
//
// Styleguide Variables.colors

// ------------------------------
// ///////////////////////////////
// ------------------------------

// Grays
//
// Grays are in the map `$gray-themes`. This maps darken white (#ffffff) to black 10% by 10%
//
// Grays can be called in the sass project using the functions:
//
// `color-gray($gray-theme, $true-val:false)` which will output by default the CSS variable
//
// Usage of font-family():
// * `color-gray(0)` => `var(--color-gray-0)` If $use-css-var = true
// * `color-gray(100)` => `#000000` If $use-css-var = false
// * `color-gray(60)` => `#666666` If $use-css-var = false
//
// <div class="has-bg-gray-0 has-p-3">0</div>
// <div class="has-bg-gray-10 has-p-3">10</div>
// <div class="has-bg-gray-20 has-p-3">20</div>
// <div class="has-bg-gray-30 has-p-3">30</div>
// <div class="has-bg-gray-40 has-p-3">40</div>
// <div class="has-bg-gray-50 has-p-3">50</div>
// <div class="has-bg-gray-60 has-p-3">60</div>
// <div class="has-bg-gray-70 has-p-3">70</div>
// <div class="has-bg-gray-80 has-p-3">80</div>
// <div class="has-bg-gray-90 has-p-3">90</div>
// <div class="has-bg-gray-100 has-p-3">100</div>
//
//
// Styleguide Variables.colors.grays

// ------------------------------
// ///////////////////////////////
// ------------------------------

// Color Swatches
//
// <!--ONLY FOR KSS-->
// <div id="kss-map-colors" class="theme-default">
// <div class="kss-color-item" data-color="brand"></div>
// <div class="kss-color-item" data-color="branddark"></div>
// <div class="kss-color-item" data-color="altblue"></div>
// <div class="kss-color-item" data-color="primary"></div>
// <div class="kss-color-item" data-color="secondary"></div>
// <div class="kss-color-item" data-color="text"></div>
// <div class="kss-color-item" data-color="success"></div>
// <div class="kss-color-item" data-color="danger"></div>
// <div class="kss-color-item" data-color="warning"></div>
// </div>
//
// Styleguide Variables.colors.swatches
