.is-rounded:not(.btn){
  border-radius: 50%;
  overflow: hidden;
}

// ------------------------------
// KSS Documentation
// ------------------------------

// Rounded element
//
// `.is-rounded`
//
// markup:
// <div style="max-width: 500px; margin-top: 1rem"> <!-- Just for kss -->
//  <div class="aspect-ratio-container is-1x1">
//    <div class="aspect-ratio-content is-rounded">
//      <img class="media-cover" src="https://source.unsplash.com/random" alt="image description">
//    </div>
//  </div>
// </div>
//
// Styleguide Atoms.Modifiers.rounded
