.has-fixed-nav {
    padding-top: 75px;

    @include min(bp(sm)) {
        padding-top: 101px;
    }

    @include min(bp(md)) {
        padding-top: 121px;
    }
}
