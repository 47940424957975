﻿.screen-select-frame {
    background-color: color-gray(0);
    border: 1px solid color-gray(20);
    padding: 1rem;
    height: calc(100% - 2rem);
}

.screen-select .full-height {
    height: 200px;
    padding: 5px;
    margin: 5px;
}

.screen-select .half-height {
    padding: 5px;
    margin: 5px;
    height: 95px;
}

.screen-select .half-height:first-of-type {
    margin-bottom: 10px;
}


.screen-select-frame input {
    float: right;
}

.disable-module-btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    float: right;
    font-weight: bold;
}
