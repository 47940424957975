$burger-size: 60px;

button.burger {
    border: none;
    background: transparent;
    position: relative;
    cursor: pointer;
    color: color-gray(60);
    display: flex;
    align-items: center;
    flex-direction: column;
    letter-spacing: 1px;
    font-size: 12px;

    &:hover {
        text-decoration: none;
    }

    span.burger-icon {
        position: relative;
        display: block;
        width: 32px;
        height: 26px;
        margin-bottom: spacer(2);
        //FIX BLUR BUG/ISSUE ON CHROME
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(1, 1);

        &:after,
        &:before,
        span {
            width: 100%;
            height: 3px;
            border-radius: 100px;
            position: absolute;
            left: 0px;
            top: 24px;
            background: color-gray(60);
            transform: rotate(0deg);
            transition: all 0.4s;
        }

        &:after,
        &:before {
            content: "";
        }

        &:after {
            top: 0px;
            margin-top: 0px;
        }

        &:before {
            bottom: 0px;
            margin-bottom: 0px;
        }

        span {
            top: 12px;
            /*top: 50%;
            transform: translateY(-50%);*/
            //margin-top: -2px;
        }
    }

    &.is-active {

        span.burger-icon {
            &:after {
                transform: rotate(135deg);
                top: 12px;
            }

            &:before {
                transform: rotate(225deg);
                top: 12px;
                margin-bottom: 0;
            }

            span {
                transform: rotate(135deg);
            }
        }
    }
}

// ------------------------------
// KSS Documentation
// ------------------------------

// Burger
//
// Use in navigation
//
// Markup:
// <button class="burger {{modifier_class}}"><span class="burger-icon"><span></span></span>Menu</button>
//
// .is-active - active states
//
// Styleguide Atoms.burger
