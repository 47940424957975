.page-container {
    position: relative;
}

// ------------------------------
// KSS Documentation
// ------------------------------

// Page Container
//
// Page container has a position relative set. It can be used to set themes
//
// Styleguide Templates.pagecontainer