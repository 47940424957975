﻿.has-border-cols {
    > * {
        border-right: 1px solid color-gray(30);

        @include last(1) {
            border-right: none;
        }
    }
}

@each $bp, $bpKey in $breakpoints {
    @include min(#{bp(#{$bp})}) {
        .has-border-cols-#{$bp} {
            > * {
                border-right: 1px solid color-gray(30);

                @include last(1) {
                    border-right: none;
                }
            }
        }
    }
}

// ------------------------------
// KSS Documentation
// ------------------------------

// Border columns
//
// `.has-border-cols` add a right border to all children except the last one, can be use with breakpoints `.has-border-cols-{bp}`
//
// markup:
// <div class="row has-border-cols-sm">
//   <div class="col-sm-4 has-mb-4">
//     <div class="kss-box"></div>
//   </div>
//   <div class="col-sm-4 has-mb-4">
//     <div class="kss-box"></div>
//   </div>
//   <div class="col-sm-4 has-mb-4">
//     <div class="kss-box"></div>
//   </div>
// </div>
//
// Styleguide Atoms.Modifiers.borderCols