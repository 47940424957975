﻿.has-box-shadow {
    box-shadow: $box-shadow;
}

// ------------------------------
// KSS Documentation
// ------------------------------

// Box shadow
//
// Standard box shadow use around the website
//
// `.has-box-shadow`
//
// markup:
// <div class="has-p-6 has-bg-grey-0 has-box-shadow"></div>
//
// Styleguide Atoms.Modifiers.boxshadow
