﻿.is-d-none {
    display: none !important;
}

.is-d-block {
    display: block !important;
}

.is-d-inline-block {
    display: inline-block !important;
}

.is-d-inline {
    display: inline !important;
}

.is-d-flex {
    display: flex !important;
}

.is-d-inline-flex {
    display: inline-flex !important;
}

.is-d-grid {
    display: grid !important;
}

.is-d-inline-grid {
    display: inline-grid !important;
}

@each $bp, $bpKey in $breakpoints {
    @include min(#{bp(#{$bp})}) {
        .is-d-#{$bp}-none {
            display: none !important;
        }

        .is-d-#{$bp}-block {
            display: block !important;
        }

        .is-d-#{$bp}-inline-block {
            display: inline-block !important;
        }

        .is-d-#{$bp}-inline {
            display: inline !important;
        }

        .is-d-#{$bp}-flex {
            display: flex !important;
        }

        .is-d-#{$bp}-inline-flex {
            display: inline-flex !important;
        }

        .is-d-#{$bp}-grid {
            display: grid !important;
        }

        .is-d-#{$bp}-inline-grid {
            display: inline-grid !important;
        }
    }
}

// ------------------------------
// KSS Documentation
// ------------------------------

// Display utilities
//
// Display utilities helps to responsively toggle the display value of components.
//
// `.is-d-{breakpoint}-{display value}`, breakpoint is optional.
//
// **display value:**
//
// * none
// * block
// * inline-block
// * inline
// * flex
// * inline-flex
// * grid
// * inline-grid
//
// markup:
// <!--style="width: auto" for kss only-->
// <!--is-d-none-->
// <div class="is-d-none kss-box" style="width: auto">.is-d-none</div>
// <div class="is-d-none kss-box" style="width: auto">.is-d-none</div>
// <!--is-d-inline-->
// <div class="is-d-inline kss-box" style="width: auto">.is-d-inline</div>
// <div class="is-d-inline kss-box" style="width: auto">.is-d-inline</div>
// <!--is-d-block-->
// <div class="is-d-block kss-box" style="width: auto">.is-d-block</div>
// <div class="is-d-block kss-box" style="width: auto">.is-d-block</div>
// <!--is-d-inline-block-->
// <div class="is-d-inline-block kss-box" style="width: auto">.is-d-inline-block</div>
// <div class="is-d-inline-block kss-box" style="width: auto">.is-d-inline-block</div>
// <!--is-d-flex-->
// <div class="is-d-flex">
//   <div class="kss-box">parent .is-d-flex</div>
//   <div class="kss-box">parent .is-d-flex</div>
//   <div class="kss-box">parent .is-d-flex</div>
// </div>
// <!--is-d-inline-flex-->
// <div class="is-d-inline-flex">
//   <div class="kss-box">parent .is-d-inline-flex</div>
//   <div class="kss-box">parent .is-d-inline-flex</div>
// </div>
// <div class="is-d-inline-flex">
//   <div class="kss-box">parent .is-d-inline-flex</div>
//   <div class="kss-box">parent .is-d-inline-flex</div>
// </div>
// <!--is-d-grid at least col need to be set-->
// <div class="is-d-grid">
//   <div class="kss-box">parent .is-d-grid</div>
//   <div class="kss-box">parent .is-d-grid</div>
//   <div class="kss-box">parent .is-d-grid</div>
// </div>
// <!--is-d-inline-grid at least col need to be set-->
// <div class="is-d-inline-grid">
//   <div class="kss-box">parent .is-d-inline-grid</div>
//   <div class="kss-box">parent .is-d-inline-grid</div>
// </div>
// <div class="is-d-inline-grid">
//   <div class="kss-box">parent .is-d-inline-grid</div>
//   <div class="kss-box">parent .is-d-inline-grid</div>
// </div>
//
// Styleguide Atoms.Modifiers.display
