﻿.overlay {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: transparentize(#000, 0.7);
        z-index: 0;
    }
    .overlay-content {
        position: relative;
        z-index: 1;
    }
}


// ------------------------------
// KSS Documentation
// ------------------------------

// Overlay
//
// `.overlay` add a black tint transparent color to the background image, it needs a `.overlay-content` children
//
// markup:
// <section class="bg-cover overlay" style="background-image: url(https://source.unsplash.com/random)">
//     <div class="overlay-content has-text-center">
//         <h1>Overlay</h1>
//     </div>
// </section>
// <!--No overlay-->
// <section class="bg-cover" style="background-image: url(https://source.unsplash.com/random)">
//     <div class="overlay-content has-text-center">
//         <h1>No Overlay</h1>
//     </div>
// </section>
//
// Styleguide Atoms.overlay