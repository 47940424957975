﻿.btn {
    cursor: pointer;
    padding: 8px 30px;
    letter-spacing: 1px;
    font-weight: 500;
    border: 2px solid color(primary);
    background-color: transparent;
    text-decoration: none;
    color: color(primary);
    display: table;
    max-width: 100%;
    text-align: center;

    @include max(bp(xs)) {
        padding: 8px 15px;
    }

    &.btn-primary,
    &.btn-red-s,
    &.btn-red {
        background: color(primary);
        color: white;
        border-color: color(primary);

        &:hover,
        &:focus {
            background-color: white;
            color: color(primary);
            border-color: color(primary);
        }
    }

    &.btn-green-s {
        background-color: color(sec);
        border-color: color(sec);
        color: white;

        &:hover,
        &:focus {
            background-color: color-gray(0);
            color: color(sec);
        }
    }

    &.btn-orange {
        background-color: color(ter);
        border-color: color(ter);
        color: white;

        &:hover,
        &:focus {
            background-color: white;
            color: color(ter);
        }
    }

    &.btn-success {
        background-color: color(quat);
        border-color: color(quat);
        color: white;

        &:hover,
        &:focus {
            background-color: white;
            color: color(quat);
        }
    }

    &.btn-danger, &.btn-warning {
        background-color: color(ter);
        border-color: color(ter);
        color: white;

        &:hover,
        &:focus {
            background-color: color-gray(0);
            color: color(ter);
        }
    }

    &.btn-white {
        background-color: color-gray(0);
        border-color: color-gray(0);
        color: color(primary);
    }

    &.btn-left {
        float: left;
    }

    &.btn-right {
        float: right;
    }

    &.btn-center {
        margin-left: auto;
        margin-right: auto;
    }

    &.is-w-100-xs {
        @include max(bp(xs)) {
            width: 100%;
        }
    }

    &.is-w-100-sm {
        @include max(bp(sm)) {
            width: 100%;
        }
    }

    &.is-w-100-md {
        @include max(bp(md)) {
            width: 100%;
        }
    }

    &.is-w-100-lg {
        @include max(bp(lg)) {
            width: 100%;
        }
    }
}
