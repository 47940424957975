﻿.account-footer {
    position: relative;
    *:not(.btn) {
        color: white;
    }

    &::before {
        display: block;
        background-image: url(/content/images/red_long_top_grainy.png);
        background-repeat: repeat-x;
        height: 18px;
        top: -17px;
        position: relative;
        content: '';
    }
}
