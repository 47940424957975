.aspect-ratio-container {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
  }
  > .aspect-ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @each $name, $aspect-ratio in $aspect-ratios {
    &.is-#{$name}{
      &:before {
        padding-top:  (map-deep-get($aspect-ratios, $name, height) / map-deep-get($aspect-ratios, $name, width)) * 100%; 
      }
    }
  }
}

// ------------------------------
// KSS Documentation
// ------------------------------

// Aspect ratio container
//
// Responsive images class
//
// Markup:
// <div style="max-width: 300px"> <!-- Just for kss -->
//  <div class="aspect-ratio-container {{modifier_class}}">
//    <div class="aspect-ratio-content has-bg-primary">{{modifier_class}}</div>
//  </div>
// </div>
//
// .is-1x1 - 1x1
// .is-16x9 - 16x9
// .is-4x3 - 4x3
// .is-golden - golden
// .is-silver - silver
//
// Styleguide Atoms.Aspectratiocontainer