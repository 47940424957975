.form-group {
    margin-bottom: 0;

    @include clearfix();

    label {
        font-size: 1rem;
        display: block;
        margin-bottom: 0.25rem;
    }

    &.checkbox {
        @include clearfix();
        //This is the actual label, usually a span / strong
        .label {
            font-weight: 500;
            padding: 0 0 0 0.5rem;
            font-size: 1rem;
            color: color(text);
            height: 22px;
            display: inline-block;
            vertical-align: sub;
            text-align: left;
            white-space: normal;
        }
    }

    + .form-group {
        margin-top: 1rem;
    }
}
