// ------------------------------
// Sass Variables
// ------------------------------
$box-shadow: -7px 9px 8px rgba(0, 0, 0, 0.10);

// ------------------------------
// KSS Documentation
// ------------------------------

// Box shadow
//
// Standard box shadow use around the website
//
// `$box-shadow: 1px 5px 10px rgba(0,0,0,.1);`
//
// markup:
// <div class="has-p-3 has-bg-grey-0 has-box-shadow"></div>
//
// Styleguide Variables.boxshadow
