﻿
.accordion-items {

    .panel {
        background-color: #f1f1f1;
        border-radius: 0;
        border: 1px solid #ededed;
        box-shadow: none;

        .panel-heading {
            border-bottom: 0;
            padding: 0;

            .panel-title a {
                display: block;
                color: black;
                padding: 20px;
                position: relative;
                text-align: left;

                i {
                    position: absolute;
                    right: 20px;
                    top: calc(50% - 11px);
                    font-size: 1rem;
                    transform: rotate(0deg);
                }

                &:hover, &:focus {
                    color: #ec2329;
                    text-decoration: none;

                    i {
                        color: #ec2329;
                    }
                }

                &:not(.collapsed) {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .panel-body {
            background-color: whitesmoke;
            padding: 20px 20px 10px 20px;
        }
    }

    &.has-white-panels {
        .panel {
            .panel-heading, .panel-body {
                background-color: white;
            }
        }
    }
}
