﻿.jumbo-square {
    height: 0;
    overflow: hidden;
    background-color: color(primary, light);
    padding-top: 75%;
    position: relative;

    .centered-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-self: center;
        padding: 1rem;
        text-align: center;

        * {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.grey-background {
        background-color: color-gray(60);

        * {
            color: white;
        }
    }
}
